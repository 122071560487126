import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-table-colgroup" }
const _hoisted_2 = ["width"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("colgroup", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, index) => {
      return (_openBlock(), _createElementBlock(_Fragment, null, [
        (header.id === 'HAVESTATUS')
          ? (_openBlock(), _createElementBlock("col", {
              key: `col_${index}`,
              width: '20px'
            }))
          : (header.id === 'SELECTABLE')
            ? (_openBlock(), _createElementBlock("col", {
                key: `col_${index}`,
                width: '20px'
              }))
            : (header.visible)
              ? (_openBlock(), _createElementBlock("col", {
                  class: "app-table-colgroup__col",
                  key: `col_${index}`,
                  width: header.width
                }, null, 8, _hoisted_2))
              : _createCommentVNode("", true)
      ], 64))
    }), 256))
  ]))
}