import { defineComponent } from 'vue';

const component = defineComponent({
  emits: ['select'],
  props: {
    items: { type: Array },
    current: { type: Object },
    disabled: { type: Boolean, default: false },
    text: { type: String, default: 'name' },
    key: { type: String, default: 'id' },
  },
  data() {
    return {
      isVisible: false,
    };
  },
  computed: {
    visible: {
      set(value: boolean) {
        if (this.disabled) return;

        this.isVisible = value;
      },
      get(): boolean {
        return this.isVisible;
      },
    },
  },
  methods: {
    documentClick(e: Event) {
      const el = this.$refs.dropdownSelect as HTMLElement;
      const target = e.target as Node;

      this.visible = this.visible && (!el || el === target || el.contains(target));
    },
    toggleSelect() {
      this.visible = !this.visible;
    },
    select(value: unknown) {
      this.$emit('select', value);
    },
  },
  mounted() {
    document.body.addEventListener('click', this.documentClick);
  },
  unmounted() {
    document.body.addEventListener('click', this.documentClick);
  },
});

export default component;
