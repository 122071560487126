<template>
  <span class="app-table-count-item"> {{ fromItem }}-{{ toItem }} из {{ totalItems }} </span>
</template>

<script>
import './tableCountItem.scss';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTableCountItem',
  props: {
    pageItems: {
      type: Number,
      default: 100,
    },
    page: {
      type: Number,
      default: 1,
    },
    totalItems: {
      type: Number,
      required: true,
    },
  },
  computed: {
    fromItem() {
      const { pageItems, page } = this;

      return +pageItems * (page - 1) + 1;
    },
    toItem() {
      const { pageItems, page, totalItems } = this;
      const count = +pageItems * page;

      return count > totalItems ? totalItems : count;
    },
  },
});
</script>
