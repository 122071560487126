
import './tableBody.scss';

import { defineComponent, PropType } from 'vue';

import { EMPTY_BODY } from '@/lib/util/consts';
import {
  TableItemTrackByFunction,
  TableItemSelectByFunction,
  TableGetStatusFunction,
} from '@/interfaces/ui/table/common.interface';
import { TableItemInterface } from '@/interfaces/ui/table/item.interface';
import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

import AppTableBodyText from '@/components/ui/table/__components/TableBodyText.vue';
import AppTableStatus from '@/components/ui/table/__components/TableStatus.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import AppTableBodyGroupTitle from '@/components/ui/table/__components/TableBodyGroupTitle.vue';

const component = defineComponent({
  name: 'AppTableBody',
  components: {
    AppTableBodyGroupTitle,
    AppTableStatus,
    AppSimpleCheckbox,
    AppTableBodyText,
  },
  emits: ['check', 'click'],
  props: {
    headers: { type: Array as PropType<TableHeaderInterface[]>, required: true },
    items: { type: Array as PropType<TableItemInterface[]>, required: true },
    noDataText: { type: String, default: EMPTY_BODY },
    editText: { type: String, default: 'Режим редактирования' },
    selectableItems: { type: Set, default: () => new Set() },
    isHover: { type: Boolean, default: false },
    isEdit: { type: Boolean, default: false },
    getStatus: { type: Function as PropType<TableGetStatusFunction> },
    value: { type: Array, default: () => [] },
    group: { type: Function },
    trackBy: { type: Function as PropType<TableItemTrackByFunction> },
    selectBy: { type: Function as PropType<TableItemSelectByFunction> },
  },
  data() {
    return {
      checkboxes: {},
    };
  },
  methods: {
    change(checked: boolean, id: string) {
      const value = checked ? [...this.value, id] : this.value.filter((idValue: unknown) => (idValue as string) !== id);

      this.$emit('check', value);
    },
    generateItemKey(item: TableItemInterface, index: number): string {
      return this.trackBy ? this.trackBy(item, index) : `item_${index}`;
    },
  },
});

export default component;
