import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createBlock as _createBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-table-body" }
const _hoisted_2 = {
  key: 0,
  class: "app-table-body__row app-table-body-row app-table-body-row--no-data"
}
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["align"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_table_body_text = _resolveComponent("app-table-body-text")!
  const _component_app_table_body_group_title = _resolveComponent("app-table-body-group-title")!
  const _component_app_table_status = _resolveComponent("app-table-status")!
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!

  return (_openBlock(), _createElementBlock("tbody", _hoisted_1, [
    _renderSlot(_ctx.$slots, "body.prepend", _normalizeProps(_guardReactiveProps({ headers: _ctx.headers, items: _ctx.items }))),
    (!_ctx.items.length || _ctx.isEdit)
      ? (_openBlock(), _createElementBlock("tr", _hoisted_2, [
          _renderSlot(_ctx.$slots, "bodyText", {}, () => [
            _createVNode(_component_app_table_body_text, {
              colspan: _ctx.headers.length,
              text: _ctx.isEdit ? _ctx.editText : _ctx.noDataText
            }, null, 8, ["colspan", "text"])
          ])
        ]))
      : (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(_ctx.items, (item, indexItem) => {
          return (_openBlock(), _createElementBlock(_Fragment, {
            key: _ctx.generateItemKey(item, indexItem)
          }, [
            (_ctx.group)
              ? (_openBlock(), _createBlock(_component_app_table_body_group_title, {
                  key: 0,
                  length: _ctx.items.length,
                  name: _ctx.group(item),
                  "before-name": _ctx.items[indexItem - 1] && _ctx.group(_ctx.items[indexItem - 1])
                }, null, 8, ["length", "name", "before-name"]))
              : _createCommentVNode("", true),
            _createElementVNode("tr", {
              class: _normalizeClass(["app-table-body__row app-table-body-row app-table-body-row--data", {
            'app-table-body-row--hover': _ctx.isHover,
            'app-table-body-row--selected': indexItem in _ctx.checkboxes && _ctx.checkboxes[indexItem].value,
            [`app-table-body-row--index-${indexItem}`]: true,
            [`app-table-body-row--id-${item?.id}`]: item?.id,
          }]),
              onClick: ($event: any) => (_ctx.$emit('click', { item, indexItem }))
            }, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.headers, (header, indexHeader) => {
                return (_openBlock(), _createElementBlock(_Fragment, null, [
                  (header.id === 'HAVESTATUS')
                    ? (_openBlock(), _createElementBlock("td", {
                        key: `row_${indexItem}_cell_have_status_${indexHeader}`,
                        class: "app-table-body__cell app-table-body-cell app-table-body-cell--status"
                      }, [
                        _renderSlot(_ctx.$slots, "status", _normalizeProps(_guardReactiveProps((_ctx.getStatus && _ctx.getStatus(item)) || {})), () => [
                          _createVNode(_component_app_table_status, _normalizeProps(_guardReactiveProps((_ctx.getStatus && _ctx.getStatus(item)) || {})), null, 16)
                        ])
                      ]))
                    : (header.id === 'SELECTABLE')
                      ? (_openBlock(), _createElementBlock("td", {
                          key: `row_${indexItem}_cell_selectable_${indexHeader}`,
                          class: "app-table-body__cell app-table-body-cell app-table-body-cell--checkbox"
                        }, [
                          (_ctx.selectableItems.has(_ctx.selectBy?.(item) || item.id))
                            ? (_openBlock(), _createBlock(_component_app_simple_checkbox, {
                                key: 0,
                                ref_for: true,
                                ref: 
                  (el) => {
                    if (el) _ctx.checkboxes[indexItem] = el;
                  }
                ,
                                isFill: true,
                                "model-value": _ctx.value.includes(_ctx.selectBy?.(item) || item.id),
                                "true-value": true,
                                "false-value": false,
                                onUpdate: ($event: any) => (_ctx.change($event, _ctx.selectBy?.(item) || item.id))
                              }, null, 8, ["model-value", "onUpdate"]))
                            : _createCommentVNode("", true)
                        ]))
                      : (header.visible)
                        ? (_openBlock(), _createElementBlock("td", {
                            key: `row_${indexItem}_cell_${header.name}_${indexHeader}`,
                            class: _normalizeClass(["app-table-body__cell app-table-body-cell", header.cellClass]),
                            align: header.bAlign || header.align
                          }, [
                            _renderSlot(_ctx.$slots, `item-${header.id}`, _normalizeProps(_guardReactiveProps({ value: item[header.id], headers: _ctx.headers, header, items: _ctx.items, item, indexItem })), () => [
                              _renderSlot(_ctx.$slots, "item", _normalizeProps(_guardReactiveProps({ value: item[header.id], headers: _ctx.headers, header, items: _ctx.items, item, indexItem })), () => [
                                _createTextVNode(_toDisplayString((typeof item[header.id] === 'object' ? '' : item[header.id]) || header.default || ''), 1)
                              ])
                            ])
                          ], 10, _hoisted_4))
                        : _createCommentVNode("", true)
                ], 64))
              }), 256))
            ], 10, _hoisted_3)
          ], 64))
        }), 128))
  ]))
}