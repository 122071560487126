import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, renderList as _renderList, createSlots as _createSlots, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-table-content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_table_colgroup = _resolveComponent("app-table-colgroup")!
  const _component_app_table_header = _resolveComponent("app-table-header")!
  const _component_app_table_body = _resolveComponent("app-table-body")!
  const _component_app_scrollbar = _resolveComponent("app-scrollbar")!
  const _component_app_table_footer = _resolveComponent("app-table-footer")!
  const _component_gui_loader = _resolveComponent("gui-loader")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-table", {
      'app-table--no-footer': !_ctx.defaultFooter || _ctx.isEdit,
      'app-table--no-data': !_ctx.items.length,
      'app-table--edit': _ctx.isEdit,
      'app-table--blocked': _ctx.isBlocked || _ctx.isLoading,
    }])
  }, [
    _renderSlot(_ctx.$slots, "top"),
    _createElementVNode("div", {
      class: _normalizeClass(["app-table__wrapper app-table-wrapper", {
        'app-table-wrapper--blocked': _ctx.isBlocked || _ctx.isLoading,
      }])
    }, [
      _createVNode(_component_app_scrollbar, {
        class: "app-table-scrollbar",
        "padding-top": 40
      }, {
        default: _withCtx(() => [
          _createElementVNode("table", _hoisted_1, [
            _createVNode(_component_app_table_colgroup, { headers: _ctx.visibleColumn }, null, 8, ["headers"]),
            _renderSlot(_ctx.$slots, "header", {}, () => [
              _createVNode(_component_app_table_header, {
                class: "app-table-content__header",
                headers: _ctx.visibleColumn,
                "selection-indicator": _ctx.selectionIndicator,
                "disabled-select": !_ctx.selectableItems.size,
                "is-edit": _ctx.isEdit,
                "start-drag": _ctx.startDrag,
                "end-drag": _ctx.endDrag,
                onCheck: _ctx.selectAllRows,
                onChange: _ctx.sortHeaders
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.$slots, (_, name) => {
                  return {
                    name: name,
                    fn: _withCtx((slotData) => [
                      _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))
                    ])
                  }
                })
              ]), 1032, ["headers", "selection-indicator", "disabled-select", "is-edit", "start-drag", "end-drag", "onCheck", "onChange"])
            ]),
            _renderSlot(_ctx.$slots, "body", {}, () => [
              _createVNode(_component_app_table_body, {
                class: "app-table-content__body",
                headers: _ctx.visibleColumn,
                items: _ctx.items,
                "selectable-Items": _ctx.selectableItems,
                "select-by": _ctx.selectBy,
                "get-status": _ctx.getStatus,
                "is-edit": _ctx.isEdit,
                value: _ctx.value,
                "is-hover": _ctx.isHover,
                group: _ctx.group,
                "no-data-text": _ctx.noDataText,
                trackBy: _ctx.trackBy,
                onCheck: _ctx.selectRow,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('click-row', $event)))
              }, _createSlots({ _: 2 }, [
                _renderList(_ctx.$slots, (_, name) => {
                  return {
                    name: name,
                    fn: _withCtx((slotData) => [
                      _renderSlot(_ctx.$slots, name, _normalizeProps(_guardReactiveProps(slotData)))
                    ])
                  }
                })
              ]), 1032, ["headers", "items", "selectable-Items", "select-by", "get-status", "is-edit", "value", "is-hover", "group", "no-data-text", "trackBy", "onCheck"])
            ])
          ])
        ]),
        _: 3
      }),
      _renderSlot(_ctx.$slots, "footer", {}, () => [
        (_ctx.defaultFooter && !_ctx.isEdit)
          ? (_openBlock(), _createBlock(_component_app_table_footer, {
              key: 0,
              page: _ctx.page,
              "total-pages": _ctx.totalPages,
              "total-items": _ctx.totalItems,
              "page-items": _ctx.pageItems,
              "per-page-items": _ctx.perPageItems,
              "hide-selectable-count-elements-on-page": _ctx.hideSelectableCountElementsOnPage,
              onChangePage: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('change-page', $event))),
              onSelectAmount: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('select-amount', $event)))
            }, {
              extra: _withCtx((slotData) => [
                _renderSlot(_ctx.$slots, "footer-extra", _normalizeProps(_guardReactiveProps(slotData)))
              ]),
              _: 3
            }, 8, ["page", "total-pages", "total-items", "page-items", "per-page-items", "hide-selectable-count-elements-on-page"]))
          : _createCommentVNode("", true)
      ]),
      (_ctx.isLoading)
        ? (_openBlock(), _createBlock(_component_gui_loader, {
            key: 0,
            absoluteCenter: ""
          }))
        : _createCommentVNode("", true)
    ], 2)
  ], 2))
}