import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-pagination" }
const _hoisted_2 = { class: "app-pagination__pages app-pagination-pages-list" }
const _hoisted_3 = ["onClick"]
const _hoisted_4 = ["onClick"]
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_font_awesome_icon = _resolveComponent("font-awesome-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!_ctx.isFirst)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["app-pagination__button app-pagination-button", { 'app-pagination-button--disabled': _ctx.isFirst }]),
          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.before && _ctx.before(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "cp-btn-add",
            icon: ['fas', 'chevron-left']
          })
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.totalPages, (number, key) => {
        return (_openBlock(), _createElementBlock(_Fragment, null, [
          (number <= 2 || number > _ctx.totalPages - 2)
            ? (_openBlock(), _createElementBlock("li", {
                key: number + Math.random() + key,
                class: _normalizeClass(["app-pagination-pages-list__item app-pagination-pages-list-item", { 'app-pagination-pages-list-item--active': number === _ctx.page }]),
                onClick: ($event: any) => (_ctx.setPage(number))
              }, _toDisplayString(number), 11, _hoisted_3))
            : (number >= _ctx.page - 1 && number <= _ctx.page + 1)
              ? (_openBlock(), _createElementBlock("li", {
                  key: number + Math.random() + key,
                  class: _normalizeClass(["app-pagination-pages-list__item app-pagination-pages-list-item", { 'app-pagination-pages-list-item--active': number === _ctx.page }]),
                  onClick: ($event: any) => (_ctx.setPage(number))
                }, _toDisplayString(number), 11, _hoisted_4))
              : (number === Math.floor((_ctx.startPage + _ctx.page) / 2) || number === Math.floor((_ctx.page + _ctx.totalPages + 1) / 2))
                ? (_openBlock(), _createElementBlock("li", {
                    key: number + Math.random() + key,
                    class: "app-pagination-pages-list__item app-pagination-pages-list-item",
                    onClick: ($event: any) => (_ctx.setPage(number))
                  }, " ... ", 8, _hoisted_5))
                : _createCommentVNode("", true)
        ], 64))
      }), 256))
    ]),
    (!_ctx.isLast)
      ? (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: _normalizeClass(["app-pagination__button app-pagination-button", { 'app-pagination-button--disabled': _ctx.isLast }]),
          onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.next && _ctx.next(...args)))
        }, [
          _createVNode(_component_font_awesome_icon, {
            class: "cp-btn-add",
            icon: ['fas', 'chevron-right']
          })
        ], 2))
      : _createCommentVNode("", true)
  ]))
}