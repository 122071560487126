import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "app-table-header" }
const _hoisted_2 = ["data-swappable"]
const _hoisted_3 = ["data-swappable"]
const _hoisted_4 = ["align", "data-swappable"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_simple_checkbox = _resolveComponent("app-simple-checkbox")!
  const _component_app_table_header_cell_content = _resolveComponent("app-table-header-cell-content")!
  const _component_draggable = _resolveComponent("draggable")!

  return (_openBlock(), _createElementBlock("thead", _hoisted_1, [
    _createVNode(_component_draggable, {
      class: "app-table-header__row",
      tag: "tr",
      group: "headers",
      handle: ".app-table-header-cell-content__drag",
      filter: "[data-swappable='false']",
      selectedClass: "app-table-header-cell--selected",
      ghostClass: "app-table-header-cell--ghost",
      chosenClass: "app-table-header-cell--chosen",
      dragClass: "app-table-header-cell--drag",
      "item-key": "id",
      disabled: !this.isEdit,
      modelValue: _ctx.headers,
      move: _ctx.isMove,
      onUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.change($event))),
      onAdd: _cache[1] || (_cache[1] = ($event: any) => (_ctx.change($event))),
      onRemove: _cache[2] || (_cache[2] = ($event: any) => (_ctx.change($event))),
      onStart: _ctx.startDrag,
      onEnd: _ctx.endDrag
    }, {
      item: _withCtx(({ element: header }) => [
        (header.id === 'HAVESTATUS')
          ? (_openBlock(), _createElementBlock("th", {
              key: 0,
              class: "app-table-header__cell app-table-header-cell app-table-header-cell--status",
              "data-swappable": header.swappable,
              style: {"width":"20px"}
            }, null, 8, _hoisted_2))
          : (header.id === 'SELECTABLE')
            ? (_openBlock(), _createElementBlock("th", {
                key: 1,
                class: "app-table-header__cell app-table-header-cell app-table-header-cell--checkbox",
                "data-swappable": header.swappable,
                style: {"width":"20px"}
              }, [
                _createVNode(_component_app_simple_checkbox, {
                  "is-fill": true,
                  indeterminate: _ctx.selectionIndicator === 'some',
                  "model-value": _ctx.selectionIndicator === 'any',
                  "true-value": true,
                  "false-value": false,
                  disabled: _ctx.disabledSelect,
                  onUpdate: _ctx.check
                }, null, 8, ["indeterminate", "model-value", "disabled", "onUpdate"])
              ], 8, _hoisted_3))
            : (header.visible)
              ? (_openBlock(), _createElementBlock("th", {
                  key: 2,
                  class: _normalizeClass(["app-table-header__cell app-table-header-cell", header.class]),
                  align: header.hAlign || header.align || 'left',
                  "data-swappable": header.swappable,
                  style: _normalizeStyle({ width: header.width })
                }, [
                  _renderSlot(_ctx.$slots, `header-item-${header.id}`, _normalizeProps(_guardReactiveProps({ headers: _ctx.headers, header })), () => [
                    _renderSlot(_ctx.$slots, "header.item", _normalizeProps(_guardReactiveProps({ headers: _ctx.headers, header })), () => [
                      _createVNode(_component_app_table_header_cell_content, {
                        class: "app-table-header-cell-content",
                        "is-edit": _ctx.isEdit,
                        sortable: header.sortable,
                        sort: header.sort,
                        "is-right": (header.hAlign || header.align) === 'right',
                        onSort: ($event: any) => (_ctx.sort(header))
                      }, {
                        default: _withCtx(() => [
                          _renderSlot(_ctx.$slots, `header-item-content-${header.id}`, _normalizeProps(_guardReactiveProps({ headers: _ctx.headers, header })), () => [
                            _createTextVNode(_toDisplayString(header.name), 1)
                          ])
                        ]),
                        _: 2
                      }, 1032, ["is-edit", "sortable", "sort", "is-right", "onSort"])
                    ])
                  ]),
                  _renderSlot(_ctx.$slots, "header-switch", _normalizeProps(_guardReactiveProps({ headers: _ctx.headers, header })))
                ], 14, _hoisted_4))
              : _createCommentVNode("", true)
      ]),
      _: 3
    }, 8, ["disabled", "modelValue", "move", "onStart", "onEnd"])
  ]))
}