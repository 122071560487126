
import { defineComponent } from 'vue';

import './pagination.scss';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';

library.add(faChevronRight, faChevronLeft);

const component = defineComponent({
  name: 'AppPagination',
  emits: ['change-page'],
  props: {
    page: { type: Number, required: true },
    totalPages: { type: Number, required: true },
  },
  data() {
    return {
      startPage: 1,
    };
  },
  computed: {
    isFirst(): boolean {
      return this.page <= 1;
    },
    isLast(): boolean {
      return this.page >= this.totalPages;
    },
  },
  methods: {
    setPage(number: number) {
      this.$emit('change-page', number);
    },
    before() {
      if (this.isFirst) return;
      this.$emit('change-page', this.page - 1);
    },
    next() {
      if (this.isLast) return;
      this.$emit('change-page', this.page + 1);
    },
  },
});

export default component;
