
import './tableHeader.scss';

import { defineComponent, PropType } from 'vue';

import { EventSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { checkboxWithIndeterminate } from '@/interfaces/ui/common.interface';

import AppTableHeaderCellContent from '@/components/ui/table/__components/TableHeaderCellContent.vue';
import AppSimpleCheckbox from '@/components/ui/form/SimpleCheckbox.vue';
import Draggable from 'vuedraggable';

const component = defineComponent({
  name: 'AppTableHeader',
  components: {
    Draggable,
    AppTableHeaderCellContent,
    AppSimpleCheckbox,
  },
  emits: ['check', 'change'],
  inject: ['sort'],
  props: {
    headers: { type: Array as PropType<TableHeaderInterface[]>, required: true },
    selectionIndicator: { type: String as PropType<checkboxWithIndeterminate> },
    disabledSelect: { type: Boolean },
    isEdit: { type: Boolean, default: false },
    startDrag: { type: Function },
    endDrag: { type: Function },
  },
  methods: {
    check(value: boolean) {
      this.$emit('check', value);
    },
    isMove(info: { from: number; to: number; draggedContext: { futureIndex: number; element: { swappable: unknown } } }) {
      if (info.from !== info.to) {
        return true;
      }

      const context = info.draggedContext;
      const newIndex = context.futureIndex;

      return context.element.swappable && this.headers[newIndex].swappable;
    },
    change(e: EventSortHeader): void {
      const { type, newIndex, oldIndex } = e;
      const headers = [...this.headers];
      this.$emit('change', {
        headers,
        changed: {
          type,
          newIndex,
          oldIndex,
        },
      });
    },
  },
});

export default component;
