
import './table.scss';

import { defineComponent, PropType } from 'vue';

import { EMPTY_BODY } from '@/lib/util/consts';
import { TableItemTrackByFunction, TableItemSelectByFunction } from '@/interfaces/ui/table/common.interface';
import { EventChangeSortHeader, TableHeaderInterface } from '@/interfaces/ui/table/header.interface';
import { TableItemInterface } from '@/interfaces/ui/table/item.interface';

import AppTableHeader from '@/components/ui/table/__components/TableHeader.vue';
import AppTableColgroup from '@/components/ui/table/__components/TableColgroup.vue';
import AppTableBody from '@/components/ui/table/__components/TableBody.vue';
import AppTableFooter from '@/components/ui/table/__components/TableFooter.vue';
import AppScrollbar from '@/components/ui/scroll/Scrollbar.vue';
import { GuiLoader } from '@library/gigant_ui';

const component = defineComponent({
  name: 'AppTable',
  components: {
    AppScrollbar,
    AppTableFooter,
    AppTableColgroup,
    AppTableBody,
    AppTableHeader,
    GuiLoader,
  },
  emits: ['change-page', 'select-amount', 'sort', 'select-row', 'sort-headers', 'click-row'],
  props: {
    headers: { type: Array as PropType<TableHeaderInterface[]>, required: true },
    items: { type: Array as PropType<TableItemInterface[]>, required: true },
    noDataText: { type: String, default: EMPTY_BODY },
    isHover: { type: Boolean, default: false },
    haveStatus: { type: Boolean, default: false },
    selectable: { type: [Boolean, Function], default: false },
    getStatus: { type: Function as PropType<(row: unknown) => { color?: string; text?: number | string }> },
    isEdit: { type: Boolean, default: false },
    hideSelectableCountElementsOnPage: { type: Boolean },
    defaultFooter: { type: Boolean, default: true },
    page: { type: Number },
    totalPages: { type: Number },
    totalItems: { type: Number },
    pageItems: { type: Number },
    value: { type: Array, default: () => [] },
    isBlocked: { type: Boolean },
    isLoading: { type: Boolean, default: false },
    startDrag: { type: Function },
    endDrag: { type: Function },
    group: { type: Function },
    trackBy: { type: Function as PropType<TableItemTrackByFunction> },
    selectBy: { type: Function as PropType<TableItemSelectByFunction> },
    perPageItems: { type: Array },
  },
  data() {
    const visibleColumn: TableHeaderInterface[] = [];

    return { visibleColumn };
  },
  computed: {
    selectionIndicator() {
      const { value, selectable, selectableItems } = this;

      if (!selectable) {
        return;
      }

      if (!value || !value.length) {
        return 'no';
      }

      if (value.length < selectableItems.size) {
        return 'some';
      }

      return 'any';
    },
    selectableItems(): Set<unknown> {
      const { items, selectable, selectBy } = this;
      const setIds = new Set();

      if (selectable === true) {
        items.forEach((item: TableItemInterface, index: number) => setIds.add(selectBy?.(item) || item.id || index));
      }

      if (typeof selectable === 'function') {
        items.forEach((item: TableItemInterface, index: number) => {
          if (!selectable(item)) {
            return;
          }

          setIds.add(selectBy?.(item) || item.id || index);
        });
      }

      return setIds;
    },
  },
  watch: {
    selectableItems(selectableItems: Set<unknown>) {
      const value = this.value.filter((id: unknown) => selectableItems.has(id));

      this.$emit('select-row', value);
    },
    headers: {
      immediate: true,
      handler(headers: TableHeaderInterface[]) {
        const { selectable, haveStatus } = this;
        const computedHeaders: TableHeaderInterface[] = [];

        if (haveStatus) {
          computedHeaders.push({ id: 'HAVESTATUS', name: '', swappable: false });
        }

        if (selectable) {
          computedHeaders.push({ id: 'SELECTABLE', name: '', swappable: false });
        }

        this.visibleColumn = computedHeaders.concat(headers);
      },
    },
  },
  methods: {
    sort(headerItem: TableHeaderInterface) {
      this.$emit('sort', headerItem);
    },
    selectRow(value: string[]) {
      this.$emit('select-row', value);
    },
    selectAllRows(value: boolean) {
      if (!value) {
        this.$emit('select-row', []);

        return;
      }

      const arr: string[] = [];
      this.selectableItems.forEach((el: unknown) => arr.push(el as string));

      this.$emit('select-row', arr);
    },
    sortHeaders(e: EventChangeSortHeader): void {
      let countColumnDelete = this.haveStatus ? 1 : 0;
      countColumnDelete += this.selectable ? 1 : 0;

      e.headers.splice(0, countColumnDelete);

      switch (e.changed.type) {
        case 'update':
          e.changed.oldIndex -= countColumnDelete;
          e.changed.newIndex -= countColumnDelete;
          break;
        case 'remove':
          e.changed.oldIndex -= countColumnDelete;
          break;
        case 'add':
          e.changed.newIndex -= countColumnDelete;
      }

      this.$emit('sort-headers', e);
    },
  },
  provide() {
    return {
      sort: this.sort,
    };
  },
});

export default component;
