
import { defineComponent, PropType } from 'vue';

import { TableHeaderInterface } from '@/interfaces/ui/table/header.interface';

const component = defineComponent({
  name: 'AppTableColgroup',
  props: {
    headers: { type: Object as PropType<TableHeaderInterface[]>, required: true },
  },
});

export default component;
