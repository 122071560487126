
import './tableBodyText.scss';

import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'AppTableNoData',
  props: {
    text: { type: String },
  },
});

export default component;
