import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-table-sort__button app-table-sort-button app-table-sort-button--top" }
const _hoisted_2 = {
  key: 1,
  class: "app-table-sort-button__id"
}
const _hoisted_3 = { class: "app-table-sort__button app-table-sort-button app-table-sort-button--bottom" }
const _hoisted_4 = {
  key: 1,
  class: "app-table-sort-button__id"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["app-table-sort", { 'app-table-sort--active': _ctx.value }])
  }, [
    _createElementVNode("button", _hoisted_1, [
      (_ctx.value !== 'DESC')
        ? (_openBlock(), _createBlock(_component_icon_font, {
            key: 0,
            class: "app-table-sort-button__icon",
            icon: "sort-up",
            size: "5"
          }))
        : (_ctx.id)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.id), 1))
          : _createCommentVNode("", true)
    ]),
    _createElementVNode("button", _hoisted_3, [
      (_ctx.value !== 'ASC')
        ? (_openBlock(), _createBlock(_component_icon_font, {
            key: 0,
            class: "app-table-sort-button__icon",
            icon: "sort-down",
            size: "5"
          }))
        : (_ctx.id)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.id), 1))
          : _createCommentVNode("", true)
    ])
  ], 2))
}