import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "app-table-select-amount__content app-table-select-amount-content" }
const _hoisted_2 = { class: "app-table-select-amount-content__input" }
const _hoisted_3 = {
  key: 0,
  class: "app-table-select-amount__list app-table-select-amount-list"
}
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!

  return (_openBlock(), _createElementBlock("div", {
    class: "app-table-select-amount",
    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toggleSelect && _ctx.toggleSelect(...args)))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.current), 1),
      _createVNode(_component_icon_font, {
        class: "app-table-select-amount-content__icon",
        icon: "down",
        size: "5"
      })
    ]),
    (_ctx.visible)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_3, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item) => {
            return (_openBlock(), _createElementBlock("li", {
              key: `table-amount-list-item_${item}`,
              class: "app-table-select-amount-list__item app-table-select-amount-list-item",
              onClick: ($event: any) => (_ctx.select(item))
            }, _toDisplayString(item), 9, _hoisted_4))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}