<template>
  <tr v-if="isNew" class="app-table-body-group-title">
    <td class="app-table-body-group-title__cell" :colspan="length">
      <span>{{ name }}</span>
    </td>
  </tr>
</template>

<script>
import './tableBodyGroupTitle.scss';

import { defineComponent } from 'vue';

export default defineComponent({
  name: 'AppTableBodyGroupTitle',
  props: {
    length: {
      type: Number,
      default: 1,
    },
    name: {
      type: String,
    },
    beforeName: {
      type: String,
    },
  },
  computed: {
    isNew() {
      const { name, beforeName } = this;

      return beforeName !== name;
    },
  },
});
</script>
