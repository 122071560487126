
import './tableStatus.scss';

import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'AppTableStatus',
  props: {
    color: { type: String },
    text: { type: [Number, String] },
    tooltip: { type: String },
  },
});

export default component;
