
import './simpleCheckbox.scss';

import { defineComponent } from 'vue';

const component = defineComponent({
  name: 'AppSimpleCheckbox',
  emits: ['update:modelValue', 'update'],
  props: {
    tag: { type: String, default: 'label' },
    modelValue: { type: [String, Number, Boolean], required: true },
    disabled: { type: Boolean, default: false },
    isFill: { type: Boolean, default: false },
    shape: { type: String },
    name: { type: String },
    indeterminate: { type: Boolean },
    trueValue: { type: [String, Number, Boolean], default: 1 },
    falseValue: { type: [String, Number, Boolean], default: 0 },
    id: { type: String },
  },
  data() {
    return {
      value: this.modelValue,
    };
  },
  watch: {
    modelValue: {
      immediate: true,
      handler() {
        this.prepareValue();
        this.$emit('update:modelValue', this.value);
      },
    },
  },
  methods: {
    update() {
      this.$emit('update', this.value);
      this.$emit('update:modelValue', this.value);
    },
    prepareValue() {
      this.value = this.modelValue === this.trueValue ? this.trueValue : this.falseValue;
    },
  },
});

export default component;
