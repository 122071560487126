import { normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, renderSlot as _renderSlot, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-table-footer" }
const _hoisted_2 = { class: "app-table-footer__item" }
const _hoisted_3 = { class: "app-table-footer__item" }
const _hoisted_4 = { class: "app-table-footer__item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_table_count_item = _resolveComponent("app-table-count-item")!
  const _component_app_pagination = _resolveComponent("app-pagination")!
  const _component_app_table_select_amount = _resolveComponent("app-table-select-amount")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "extra", _normalizeProps(_guardReactiveProps({ totalPages: _ctx.totalPages, page: _ctx.page, pageItems: _ctx.pageItems, totalItems: _ctx.totalItems })), () => [
        (_ctx.totalItems)
          ? (_openBlock(), _createBlock(_component_app_table_count_item, {
              key: 0,
              page: _ctx.page,
              "page-items": _ctx.pageItems,
              "total-items": _ctx.totalItems
            }, null, 8, ["page", "page-items", "total-items"]))
          : _createCommentVNode("", true)
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_ctx.totalPages)
        ? (_openBlock(), _createBlock(_component_app_pagination, {
            key: 0,
            page: _ctx.page,
            "total-pages": _ctx.totalPages,
            onChangePage: _ctx.changePage
          }, null, 8, ["page", "total-pages", "onChangePage"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (!_ctx.hideSelectableCountElementsOnPage)
        ? (_openBlock(), _createBlock(_component_app_table_select_amount, {
            key: 0,
            current: _ctx.pageItems,
            items: _ctx.perPageItems,
            onSelect: _ctx.selectAmount
          }, null, 8, ["current", "items", "onSelect"]))
        : _createCommentVNode("", true)
    ])
  ]))
}