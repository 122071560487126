import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, vShow as _vShow, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-table-header-cell-content__content" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table_sort = _resolveComponent("app-table-sort")!

  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass(["app-table-header-cell-content", {
      'app-table-header-cell-content--edit': _ctx.isEdit,
      'app-table-header-cell-content--right': _ctx.isRight,
    }])
  }, [
    (_ctx.isEdit)
      ? (_openBlock(), _createBlock(_component_icon_font, {
          key: 0,
          class: "app-table-header-cell-content__drag",
          icon: "drag",
          size: "10"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _withDirectives(_createVNode(_component_app_table_sort, {
      class: "app-table-header-cell-content__sort",
      value: _ctx.sort?.value,
      id: _ctx.sort?.id,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('sort')))
    }, null, 8, ["value", "id"]), [
      [_vShow, _ctx.sortable && !_ctx.isEdit]
    ])
  ], 2))
}