
import './tableFooter.scss';

import { defineComponent } from 'vue';

import AppTableSelectAmount from '@/components/ui/table/__components/TableSelectAmount.vue';
import AppPagination from '@/components/ui/pagination/Pagination.vue';
import AppTableCountItem from '@/components/ui/table/__components/TableCountItem.vue';

const component = defineComponent({
  name: 'AppTableFooter',
  components: {
    AppTableCountItem,
    AppTableSelectAmount,
    AppPagination,
  },
  emits: ['change-page', 'select-amount'],
  props: {
    page: { type: Number },
    totalPages: { type: Number },
    pageItems: { type: Number },
    totalItems: { type: Number },
    perPageItems: { type: Array },
    hideSelectableCountElementsOnPage: { type: Boolean },
  },
  methods: {
    changePage(page: number) {
      this.$emit('change-page', page);
    },
    selectAmount(amount: string) {
      this.$emit('select-amount', amount);
    },
  },
  watch: {
    totalPages(total: number) {
      if (!total || total < (this.page ?? 1)) {
        this.changePage(total ?? 1);
      }
    },
  },
});

export default component;
