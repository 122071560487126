
import './tableHeaderCellContent.scss';

import { defineComponent, PropType } from 'vue';

import { SortEntityInterface } from '@/interfaces/ui/common.interface';

import AppTableSort from '@/components/ui/table/__components/TableSort.vue';
import IconFont from '../../../icons/IconFont.vue';

const component = defineComponent({
  name: 'AppTableHeaderCellContent',
  components: { AppTableSort, IconFont },
  emits: ['sort'],
  props: {
    sortable: { type: Boolean },
    sort: { type: Object as PropType<SortEntityInterface> },
    isEdit: { type: Boolean, default: false },
    isRight: { type: Boolean, default: false },
  },
});

export default component;
