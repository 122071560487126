
import './tableSelectAmount.scss';

import { defineComponent } from 'vue';

import SelectAbstract from '@/components/ui/form/abstract/SelectAbstract';

import IconFont from '@/components/icons/IconFont.vue';

const component = defineComponent({
  name: 'AppTableSelectAmount',
  extends: SelectAbstract,
  emits: ['select'],
  components: { IconFont },
  props: {
    current: { type: Number, default: 100 },
    items: { type: Array, default: () => ['50', '100', '500', '1000'] },
  },
});

export default component;
