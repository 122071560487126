
import './tableSort.scss';

import { defineComponent, PropType } from 'vue';

import { sortValue } from '@/interfaces/ui/common.interface';

import IconFont from '../../../icons/IconFont.vue';

const component = defineComponent({
  name: 'AppTableSort',
  components: { IconFont },
  props: {
    value: { type: String as PropType<sortValue> },
    id: { type: Number },
  },
});

export default component;
